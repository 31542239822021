<template>
  <v-container fluid>
      <FinanceTopBar />
<!--    <v-row>-->
<!--      <v-col sm="12" lg="1"></v-col>-->
<!--      <v-col sm="12" lg="9" style="text-align: center; min-height: 60px">-->
<!--        <SaleTopTab :salesClass="true" :logClass="false" :invoiceClass="false" :creditClass="false"  :refundClass="false"  :cancellationClass="false" />-->
<!--      </v-col>-->
<!--      <v-col sm="12" lg="2" style="text-align: center; min-height: 60px">-->
<!--        <v-row-->
<!--          justify="center"-->
<!--          v-if="-->
<!--            checkReadPermission($modules.sales.graph.slug) ||-->
<!--            checkReadPermission($modules.sales.logs.slug) ||-->
<!--            checkReadPermission($modules.sales.credits.slug) ||-->
<!--            checkReadPermission($modules.sales.refunds.slug) ||-->
<!--            checkReadPermission($modules.sales.void.slug)-->
<!--          "-->
<!--        >-->
<!--        <v-col-->
<!--            align="center"-->
<!--            cols="4"-->
<!--            md="4"-->
<!--            v-if="checkReadPermission($modules.sales.graph.slug)"-->
<!--            @click="configDialog = true"-->
<!--          >-->
<!--            <v-icon color="#066a8c">mdi-cog</v-icon>-->
<!--            <div class="salesBtn">Config</div>-->
<!--          </v-col>-->
<!--          <v-col cols="8" md="8">-->
<!--            <div class="text-center">-->
<!--              <v-menu offset-y>-->
<!--                <template v-slot:activator="{ on, attrs }">-->
<!--                  <div dark v-bind="attrs" v-on="on">-->
<!--                    <v-icon color="#066a8c">mdi-wallet</v-icon>-->
<!--                    <div class="salesBtn">Wallet</div>-->
<!--                  </div>-->
<!--                </template>-->
<!--                <online-sales></online-sales>-->
<!--                <krews-sales></krews-sales>-->
<!--              </v-menu>-->
<!--            </div>-->
<!--          </v-col>-->
<!--          -->
<!--        </v-row>-->
<!--      </v-col>-->
<!--      &lt;!&ndash; <v-spacer></v-spacer> &ndash;&gt;-->
<!--      &lt;!&ndash; <v-col sm="12" lg="1"></v-col> &ndash;&gt;-->
<!--    </v-row>-->
    <v-row>
      <order-sales
        @stackChange="stackChangeCapture"
        :stackType="stackType"
        :showProductType="true"
        :exportPermission="checkExportPermission($modules.sales.graph.slug)"
      ></order-sales>
    </v-row>
<!--    <sales-configuration-->
<!--      @close="configDialog = false"-->
<!--      :viewConfig="configDialog"-->
<!--      :productType="'academy'"-->
<!--    ></sales-configuration>-->
  </v-container>
</template>

<script>
// import SaleTopTab from "../../components/Sale/SaleTopTab.vue";
import OrderSales from "@/components/Chart/OrderSalesGraph";
// import OnlineSales from "./OnlineSales.vue";
// import KrewsSales from "./KrewsSales.vue";
// import SalesConfiguration from "@/components/Settings/SalesConfiguration";
import FinanceTopBar from "@/components/Sale/FinanceTopBar.vue";
export default {
  components: {
    FinanceTopBar,
    // SaleTopTab,
    OrderSales,
    // OnlineSales,
    // SalesConfiguration,
    // KrewsSales,
  },
  data() {
    return {
      btnShow: false,
      stackType: "all",
      configDialog: false,
    };
  },

  mounted() {
    setTimeout(() => {
      this.btnShow = true;
    }, 10);
  },
  methods: {
    stackChangeCapture(data) {
      this.stackType = data;
    },
  },
};
</script>


<style scoped>
.ptBtn {
  padding: 5px;
  font-size: 14px;
  font-weight: bold;
  color: #066a8c;
}
.btn_bg {
  background-color: #fff;
  border-bottom: 3px solid #066a8c;
  border-radius: 10px;
  box-shadow: 0 3px 3px -2px black;
}
.personal-trainer_ic {
  background-image: url("../../assets/images/nav_icon/personal-trainer.png");
  background-position: center center;
  padding: 10px;
  background-size: contain;
}
</style>